.tech-stack-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 20px 120px;
    background-color: #f9f9f9;
    align-items: center;
    text-align: center;
}

.tech-card {
    background-color: #f9f9f9;
    border-radius: 12px;
    padding: 0px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* .tech-card .ant-card.ant-card-body {
    padding: 0;
} */

.tech-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.icon-container {
    font-size: 30px;
    color: #8d1c26;
    transition: transform 0.3s ease;
}

/* .tech-card:hover .icon-container {
    transform: rotate(360deg);
} */

.tech-title {
    font-size: 20px;
    color: #8d1c26;
    margin-bottom: 10px;
}

.tech-description {
    font-size: 16px;
    color: #595959;
}

@media (max-width: 768px) {
    .tech-stack-container {
        padding: 0px 20px;
    }

    .section-heading {
        font-size: 28px;
    }

    .section-description {
        font-size: 16px;
    }

    .tech-card {
        padding: 0px;
    }

    .tech-title {
        font-size: 18px;
    }

    .tech-description {
        font-size: 14px;
    }
/* 
    :where(.css-dev-only-do-not-override-14qglws).ant-card .ant-card-body {
        padding: 12px;
    } */
}