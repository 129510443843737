/* General Styles */
.financial-services-page {
    font-family: 'SF Pro Display', 'Roboto', sans-serif;
    color: #1f1f1f;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background: #f9f9f9;
  }
  
  /* Header */
  .header {
    background: linear-gradient(135deg, #e3e3e3, #f5f5f5);
    padding: 80px 20px;
    text-align: center;
  }
  
  .header-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .main-title {
    font-size: 48px;
    font-weight: 600;
    color: #333;
    margin-bottom: 20px;
  }
  
  .sub-title {
    font-size: 20px;
    margin-bottom: 40px;
    color: #666;
  }
  
  .action-button {
    background: #0078d4;
    color: white;
    border: none;
    padding: 12px 30px;
    font-size: 16px;
    border-radius: 25px;
  }
  
  /* Services Section */
  .fin-services {
    padding: 60px 20px;
  }
  
  .fin-service-card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    padding: 40px 20px;
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  .fin-service-card:hover {
    transform: translateY(-10px);
  }
  
  .fin-service-card h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #1f1f1f;
  }
  
  .fin-service-card p {
    font-size: 16px;
    color: #666;
  }
  
  /* Highlight Section */
  .highlight {
    background: #0078d4;
    color: white;
    padding: 80px 20px;
    text-align: center;
  }
  
  .highlight-title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .highlight-text {
    font-size: 18px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Footer Section */
  .footer {
    padding: 60px 20px;
    background: #333;
    color: white;
  }
  
  .footer-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .footer-text {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .footer-actions {
    text-align: right;
  }
  
  .footer-button {
    background: #0078d4;
    color: white;
    border: none;
    padding: 12px 30px;
    font-size: 16px;
    border-radius: 25px;
  }
  