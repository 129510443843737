.full-screen-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #e1efff 0%, #c9ccd2 100%);
    background: rgb(243, 243, 243);
    color: #fff;
}

.full-screen-row {
    width: 100%;
    height: 100%;
}

.left-half,
.right-half {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.content-container {
    max-width: 100%;
    text-align: justify;
    padding: 0 10% 0 15%;
}

.left-heading {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 20px;
    animation: slideInLeft 1s ease-out;
}

.left-paragraph {
    font-size: 18px;
    line-height: 1.6;
    animation: fadeIn 1s ease-in-out 0.5s;
    color: #4a4a4a;
    text-align: left;
}

.right-heading {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
    animation: slideInRight 1s ease-out;
}

.styled-bullets {
    list-style-type: none;
    padding: 0;
    font-size: 18px;
    text-align: left;
    animation: fadeIn 1s ease-in-out 0.5s;
}

.styled-bullets li {
    position: relative;
    padding-left: 30px;
    margin: 15px 0;
    line-height: 1.6;
}

.styled-bullets li::before {
    content: '✔';
    position: absolute;
    left: 0;
    color: #52c41a;
    font-size: 24px;
    font-weight: bold;
}

.landing-full-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  

@keyframes slideInLeft {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .left-heading {
        font-size: 32px;
    }

    .left-paragraph,
    .styled-bullets {
        font-size: 16px;
    }

    .right-heading {
        font-size: 28px;
    }

    .full-screen-container {
        padding-top: 30%;
    }

    .content-container {
        padding: 0;
    }
}