.landing-text-side {
    padding: 5%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-container {
    height: 100%;
    width: 100%;
}

img {
    width: 100%;
    /* Make the image fill the width of the container */
    height: 100%;
    /* Make the image fill the height of the container */
    object-fit: cover;
    /* Ensure the image covers the container */
    object-position: center;
    /* Center the image */
}

@media (max-width: 768px) {
    .landing-text-side {
        height: fit-content;
    }
}