
.face-page-container {
    /* display: flex; */
    /* justify-content: space-between; */
    align-items: center;
    width: 100vw;
    padding-top: 50px;
}

.cam-card {

    background-color: #f8f8f8;
}

.cam-container {
    border: none;
    border-radius: 100px;
    border-width: 10px;
    border-style: solid;
    width: 100%;

    /* flex: 2; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
}

.captured-image-container {
    width: 100%;
}

.captured-image {
    border-radius: 20px;
}

.checklist-container {
    flex: 1;
    padding: 40px;
}

.checklist-container ul {
    list-style: none;
    padding: 0;
    align-items: right;
}

.checklist-container li {
    font-size: 16px;
    margin: 10px 0;
    padding: 10px;
    /* border: 1px solid gray; */
    border-radius: 5px;
    /* background-color: #f8f8f8; */
}

.checklist-container li span {
    padding: 5px;
    border: 1px solid gray;
    border-radius: 5px;
}

.checklist-container .checked {
    /* background-color: #4caf50; */
    /* color: #4caf50; */
    color: #35a939;
}

.webcam {
    border-radius: 50%;
    border: 2px solid #4caf50;
    width: inherit;
    max-width: 300px;
    max-height: 300px;
    object-fit: cover;
}

.status-container {
    flex: 1;
    padding: 20px;
}

.failure-reason {
    color: red;
    font-weight: bold;
    justify-content: center;
}

.kyc-primary-button {
    margin: 20px auto;
    padding: 20px;
    font-size: large;
    font-weight: 700;
}

.kyc-secondary-button {
    margin: 10px auto;
    padding: 5px;
    font-size: 15px;
    font-weight: 300;
}

.missing-checkpoints-description {
    padding: 0 10%;
}

.capture-instruction {
    padding: 10% 10% 0 10%;
}

.match-instruction {
    padding: 10% 10% 0 10%;
}

.match-title {
    font-size: 16px;
    font-weight: 600;
}

.match-description {
    line-height: 2;
}

@media (max-width: 768px) {
    .cam-card {
        /* padding: 0 5%; */
        max-height: 500px;
    }

    .cam-container {
        max-width: 100vw;
        max-height: 500px;
    }

    .face-page-container {
        padding-top: 10px;
        min-height: 100vh;
    }

    .checklist-container ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    .checklist-container {
        padding: 0 40px;
    }

    .checklist-container li {
        font-size: 12px;
        margin: 0px 0;
        padding: 10px;
        min-width: fit-content;
        border-radius: 5px;
    }

    .failure-reason {
        margin-top: 20px;
    }

    .capture-instruction {
        padding: 5% 10%;
    }

    .match-instruction {
        padding: 5% 10%;
    }

    .kyc-primary-button {
        margin: 0 auto;
    }

    .kyc-secondary-button {
        margin: 10 auto;
        padding: 5px;
        font-size: 15px;
        font-weight: 300;
    }

    .captured-image-container {
        padding: 5%;
    }
}
