body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

section {
  padding: 20px;
}

.section-heading {
  font-size: 36px;
  font-weight: bold;
  color: #8d1c26;
  margin-bottom: 20px;
}

.section-description {
  font-size: 18px;
  color: #4a4a4a;
  max-width: 800px;
  margin-bottom: 50px;
  line-height: 1.8;
  text-align: center;
}


h1 {
  color: #333;
  font-size: 160px;
}

h2 {
  color: #333;
  font-size: 40px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.tile {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  width: 300px;
  text-align: left;
}

.cta-button {
  display: inline-block;
  margin: 20px 0;
  padding: 10px 20px;
  background-color: #8d1c26;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.tech-stack {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}

.tech-stack img {
  height: 50px;
  margin: 0 20px;
  animation: slide 15s linear infinite;
}

.home-header {
  background: rgb(243, 243, 243); /* Dark background for header */
  padding: 0;
  text-align: center;
  height: auto;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure the header stays on top */
}

.home-header .ant-menu {
  line-height: 64px;
  display: flex;
  justify-content: center; /* Center items horizontally */
  border: none; /* Remove default border */
  margin: 0 auto;
  background: rgb(243, 243, 243);
}

.home-header .ant-layout-header {
  padding: 0;
}

.home-header .ant-menu-item {
  color: #333333; /* White text color */
  font-size: 18px; /* Adjust font size */
  font-weight: 700;
  transition: background 0.3s, color 0.01s; /* Smooth transition effect */
}

.home-header .ant-menu-item:hover,
.home-header .ant-menu-item-active {
  background: #007bff; /* Highlighted background color on hover and active */
  color: #8e8e8e; /* Ensure text color remains white */
}

.home-header .ant-menu-item a {
  color: inherit; /* Ensure link color matches menu item color */
}

.home-header .ant-menu-item a:hover {
  color: #8e8e8e; /* Ensure link color remains white on hover */
}

.home-content {
  color: #fff; /* White text color for header */
  max-width: 800px;
  margin: 0 auto;
  padding: 0;
}

.home-content .home-title {
  margin: 0 auto;
  padding: 5px;
  font-size: 50px;
  font-weight: 900;
}

.home-intro {
  padding: 40px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 64px;
}

.home-intro-right {
  padding: 40px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 64px;
}

.ant-btn-primary {
  background: #8d1c26;
  border-color: #8d1c26;
}

.ant-btn-primary.ant-btn-solid {
  background: #8d1c26;
}

:where(.css-dev-only-do-not-override-14qglws).ant-btn-primary.ant-btn-solid:not(:disabled):not(.ant-btn-disabled):hover {
  background: #4a4a4a;
  border-color: #4a4a4a;
}

.home-intro-title {
  font-size: 22px; /* Base font size */
  text-align: justify;
  line-height: 1.4; /* Line height for better readability */
  color: rgba(0, 0, 0, 0.9); /* Dark grey color for text */
  margin-bottom: 20px; /* Space between paragraphs */
  padding: 0 60px; /* Padding on left and right for better spacing */
}

.home-para {
  font-size: 22px; /* Base font size */
  text-align: justify;
  line-height: 1.4; /* Line height for better readability */
  color: rgba(0, 0, 0, 0.9); /* Dark grey color for text */
  margin-bottom: 20px; /* Space between paragraphs */
  padding: 0 60px; /* Padding on left and right for better spacing */
}

.service-card-header {
  background-color: #d5e6f8;
  min-height: 180px;
  margin: 20px;
  padding: 10px 0 0 0;
}

.service-card-header .ant-card-head-title {
  font-size: 18px;
  font-weight: 900;
}

.service-card-header p {
  padding: 0 20px;
  font-size: 16px;
  text-align: justify;
  font-weight: 400;
}

#why-us p {
  width: 50%;
  position: relative;
  left: 25%;
  padding: 0 60px;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
}

#how-we-work p {
  width: 50%;
  position: relative;
  left: 25%;
  padding: 0 60px;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
}

.contact-block {
  margin: 0 auto;
  min-width: 300px;
  max-width: 700px;
}

.testimonials-block .container {
  margin: 0 auto;
  max-width: 80%;
}

/* Footer Styles */
.app-footer {
  background: #4a4a4a; /* Updated background color */
  color: #ffffff; /* White text color */
  padding: 20px 50px;
  text-align: center;
  margin-bottom: 0;
}

.app-footer a {
  color: #ffffff; /* Ensure link color is white */
}

.app-footer a:hover {
  color: #1890ff; /* Highlight color on hover */
}

.app-footer .ant-typography {
  color: #ffffff; /* Ensure typography color is white */
}

.social-icon {
  font-size: 24px;
  color: #ffffff;
}

.social-icon:hover {
  color: #1890ff; /* Highlight color on hover */
}

.dotted-underline {
  text-decoration: underline;           /* Apply underline */
  text-decoration-style: dotted;        /* Use dotted line style */
  text-decoration-thickness: 2px;       /* Adjust the thickness of the line (optional) */
  text-underline-offset: 4px;           /* Adjust the distance of the underline from the text (optional) */
}

@keyframes slide {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

@media (max-width: 768px) {
  .section-heading {
    font-size: 28px;
  }

  .section-description {
    font-size: 16px;
  }
}
