.why-choose-us-container {
    width: 100vw;
    padding: 60px 10%;
    background-color: #f0f4f7;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.founder-card {
    background-color: #f0f4f7;
    border-radius: 12px;
    padding: 20px;
    min-height: 100px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.founder-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.founder-avatar {
    margin-bottom: 20px;
    border: 4px solid #8d1c26;
    border-radius: 50%;
}

.founder-name {
    font-size: 16px;
    color: #4a4a4a;
    margin-bottom: 5px;
}

.founder-title {
    font-size: 22px;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 10px;
}

.founder-connect {
    font-size: 16px;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 10px;
}

.founder-description {
    font-size: 14px;
    color: #4a4a4a;
    line-height: 1.6;
    min-height: 120px;
}

@media (max-width: 768px) {
    .section-heading {
        font-size: 28px;
    }

    .section-description {
        font-size: 16px;
    }

    .founder-card {
        padding: 0px;
        min-height: fit-content;
        text-align: center;
        transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    }

    .founder-avatar {
        margin-bottom: 20px;
        border: 4px solid #8d1c26;
        border-radius: 50%;
        height: fit-content;
    }

    .founder-name {
        font-size: 20px;
    }

    .founder-title {
        font-size: 14px;
    }

    .founder-description {
        font-size: 12px;
        min-height: fit-content;
    }

    :where(.css-dev-only-do-not-override-14qglws).ant-card .ant-card-body {
        padding: 6px;
    }
}