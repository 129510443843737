.top-div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgb(243, 243, 243);
    color: #8d1c26;
    padding: 20px 0;
    /* font-size: 1.2rem; */
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    /* Smooth transition */
    z-index: 1000;
    /* Ensure it's on top of other content */
}

.top-div.hidden {
    transform: translateY(-100%);
    /* Move div up to hide it */
}

.top-div.visible {
    transform: translateY(0);
    /* Move div down to show it */
}

/* The main content of the page */
.top-page-content {
    transition: margin-top 0.3s ease-in-out;
    /* Smooth transition for margin change */
}

/* When the top div is visible, add margin to keep content below it */
.top-page-content.with-top-div {
    margin-top: 84px;
    /* Adjust this to match the height of the top div */
}

/* When the top div is hidden, remove the margin */
.top-page-content.without-top-div {
    margin-top: 0;
}

.top-main-content {
    background-color: #f5f5f5;
    font-size: 1.1rem;
}

.custom-dropdown .ant-dropdown-menu-item {
    background-color: transparent !important;
    color: inherit !important;
    transition: background-color 0.3s, color 0.3s;
}

.custom-dropdown .ant-dropdown-menu-item:hover {
    background-color: rgba(0, 0, 0, 0.05) !important; /* Optional hover effect */
    color: inherit !important;
}
