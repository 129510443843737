.animated-sentence-container {
    text-align: left;
    /* Keep the sentence left-aligned */
    width: 100%;
    max-width: 700px;
    /* Set a max width for the desktop view */
    margin: 20px auto;
    margin-left: 0;
    /* Center the container horizontally */
    padding: 0;
    /* Add padding for small screens */
}

.sentence-text {
    font-size: 3.2rem;
    font-weight: 700;
    color: #4a4a4a;
    word-wrap: break-word;
    /* Ensure long words wrap properly */
    white-space: normal;
    /* Allow the sentence to wrap */
}

.animated-word {
    display: inline-block;
    min-width: 120px;
    /* Ensure the space for the changing word remains consistent */
    text-align: left;
    color: #8d1c26;
    /* Ant Design primary color */
    font-weight: 600;
    animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive design for tablets */
@media (max-width: 768px) {
    .sentence-text {
        font-size: 2.8rem;
        /* Reduce font size on smaller screens */
    }

    .animated-word {
        min-width: 100px;
        /* Adjust space for changing word */
    }

    .animated-sentence-container {
        max-width: 90%;
        /* Reduce the width on tablet-sized screens */
    }
}

/* Responsive design for mobile devices */
@media (max-width: 480px) {
    .sentence-text {
        font-size: 2.2rem;
        /* Further reduce font size for mobile devices */
    }

    .animated-word {
        min-width: 80px;
        /* Reduce the space for the animated word */
    }

    .animated-sentence-container {
        max-width: 100%;
        /* Take full width on mobile screens */
    }
}