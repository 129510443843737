.medium-blogs-container {
    margin: 10% 5% 5% 5%;
    padding: 40px;
    background: #f5f5f5;
    min-height: 100vh;
}

.title {
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.blogs-list-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 10%;
}

.blog-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

.blog-card {
    border-radius: 50px;
    overflow: hidden;
    transition: transform 0.3s ease;
    margin-bottom: 5%;
    width: 70vw;
    background-color: #f9f9f9;
}

.blog-card:hover {
    transform: translateY(-5px);
}

.blog-image {
    width: inherit;
    height: 200px;
    object-fit: cover;
}

.blog-title {
    margin-top: 10px;
    font-weight: 600;
}

.blog-description {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    color: #555;
}

.read-more {
    align-items: center;
    color: #1890ff;
    font-weight: 500;
}
