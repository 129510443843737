.ekyc-main-page-intro {
    padding: 5% 10% 0% 10%;
    min-height: 20vh;
    margin-top: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ekyc-main-page-img {
    min-height: 50vh;
    position: relative;
    background-image: url('https://i.ibb.co/pv4MF0BL/ekyc-image1.jpg');
    /* background-image: url('https://i.ibb.co/1YqQ2H7n/ekyc-image2.jpg'); */
    background-size: cover;  /* Ensures the image covers the whole area */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat;
    width: 100vw; /* Makes sure it spans full width of the screen */
    margin-left: calc(-50vw + 50%); /* Adjusts to ignore container margins */
    opacity: 0.75;
}

.ekyc-main-page-intro img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.ekyc-main-page-intro div h2 {
    font-weight: 700;
    font-size: 70px;
}

.ekyc-main-page-intro div p span {
    font-weight: 600;
}

.ekyc-main-page-intro div p {
    font-weight: 300;
    font-size: 18px;
    line-height: 2.5ch;
    text-align: center;
}

.ekyc-main-page-title {
    padding: 5% 10%;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ekyc-main-page-title div {
    text-align: left;
}

.ekyc-main-page-title div h2 {
    font-weight: 700;
    font-size: 40px;
}

.ekyc-main-page-title div p {
    font-weight: 300;
    font-size: 20px;
    line-height: 2.5ch;
    text-align: justify;
}

.ekyc-main-page-items {
    width: 100vw;
    padding-bottom: 50px;
}

.ekyc-main-page-item {
    height: 120px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4a4a4a;
    border-radius: 16px;
    font-weight: 500;
    font-size: 20px;
    color: white;
}

.ekyc-main-page-what-we-offer {
    padding: 5% 10%;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ekyc-main-page-what-we-offer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ekyc-main-page-what-we-offer div {
    text-align: left;
}

.ekyc-main-page-what-we-offer div h2 {
    font-weight: 500;
    font-size: 50px;
}

.ekyc-main-page-what-we-offer div p {
    padding: 5% 5% 5% 0;
    font-weight: 300;
    font-size: 20px;
    line-height: 2.5ch;
    text-align: justify;
}

.ekyc_what-we-offer-list {
    /* margin: 0 auto;
    min-height: 50vh;
    width: fit-content; */
    /* display: flex;
    align-items: left;
    justify-content: center;
    text-align: center; */
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
}

.ekyc_what-we-offer-items {
    width: inherit;
    display: flex;
    align-items: left;
    justify-content: center;
    text-align: center;
}

.ekyc_what-we-offer-list-item {
    display: flex;
    align-items: left;
    justify-content: center;
    text-align: center;
}

.ekyc_what-we-offer-item {
    height: 20vh;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(83, 208, 233);
    border-radius: 16px;
    font-weight: 500;
    font-size: 12px;
}

@media (max-width: 768px) {
    .ekyc-main-page-title div h2 {
        font-size: 40px;
    }
}