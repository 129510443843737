.landing-page-4-container {
    height: 100vh;
}

.landing-text-whole {
    padding: 5%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.consult-button-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .landing-page-4-container {
        height: fit-content;
    }

    .landing-text-whole {
        padding: 5%;
        height: 55vh;
        display: flex;
        align-items:flex-end;
        justify-content: center;
    }

    .consult-button-row {
        display: flex;
        align-items:baseline;
        justify-content: center;
        max-height: 20vh;
    }
}