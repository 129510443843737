/* App.css */

.app-header {
    background: #001529;
    color: #fff;
    text-align: center;
    padding: 20px;
  }
  
  .app-header h1 {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
  }
  
  .app-header p {
    margin: 0;
    font-size: 16px;
    color: #aaa;
  }
  
  .app-footer {
    background: #4a4a4a;
    color: #fff;
    text-align: center;
    padding: 10px;
  }
  
  .content-block {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 16px;
    transition: transform 0.2s;
  }
  
  .content-block:hover {
    transform: translateY(-5px);
  }
  
  .content-container {
    padding: 20px;
  }
  
  .app-layout {
    height: 100vh;
    background: #f0f2f5;
  }
  
  .grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  