.finance-intro-heading {
    margin-top: 200px;
    width: 100vw;
}

.finance-intro-button {
    padding: 0 0 20px 0;
    height: fit-content;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(243, 243, 243);
}

.finance-intro {
    padding: 0 5% 0 5%;
    height: fit-content;
    width: 100vw;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(243, 243, 243);
}

.fin-service-card {
    background-color: rgb(243, 243, 243);
    border-radius: 12px;
    padding: 10px;
    min-height: 190px;
    text-align: center;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.fin-service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.service-title {
    font-size: 18px;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 10px;
}

.service-description {
    font-size: 16px;
    color: #4a4a4a;
    line-height: 1.6;
}

.explore-button-container {
    margin-top: 40px;
    text-align: center;
}

.explore-button {
    padding: 30px;
    font-size: 30px;
}

@media (max-width: 768px) {
    .section-heading {
        font-size: 28px;
    }

    .service-title {
        font-size: 20px;
    }

    .service-description {
        font-size: 14px;
    }

    .explore-button {
        font-size: 16px;
    }

    .services-section-container {
        height: fit-content;
    }

    .fin-service-card {
        min-height: 100px;
    }
}