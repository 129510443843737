.how-we-work-container {
  width: 100vw;
  padding: 60px 10%;
  background-color: #f0f4f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.flowchart {
  width: 100%;
  margin-bottom: 40px;
}

.flowchart-item {
  position: relative;
}

.helper-text {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4a4a4a;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 12px;
}

.flowchart-card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.flowchart-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.flowchart-title {
  font-size: 24px;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.flowchart-description {
  font-size: 16px;
  color: #595959;
  line-height: 1.6;
}

.step-details-container {
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
}

.step-detail {
  margin-bottom: 20px;
}

.step-title {
  font-size: 22px;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 10px;
}

.step-description {
  font-size: 16px;
  color: #595959;
  line-height: 1.8;
  text-align: justify;
}

.schedule-call-container {
  margin-top: 0px;
}

.schedule-call-button {
  padding: 30px;
  font-size: 30px;
}

@media (max-width: 768px) {
  .section-heading {
    font-size: 28px;
  }

  .section-description {
    font-size: 16px;
  }

  .flowchart-title {
    font-size: 20px;
  }

  .flowchart-description {
    font-size: 14px;
  }

  .step-title {
    font-size: 20px;
  }

  .step-description {
    font-size: 14px;
  }

  .schedule-call-button {
    font-size: 16px;
  }

  .flowchart-item {
    margin-top: 30px;
  }
}