
.face-page-container {
    margin-top: 150px;
}

.cam-container {
    border-radius: 5000px;
    border-width: 10px;
    border-style: solid;
    border-color: aquamarine;
}

.captured-image-container {
    width: 500px;
}