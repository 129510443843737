.ai-work-for-you-title {
    padding: 5%;
    height: 50vh;
    margin-top: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ai-work-for-you-title div {
    text-align: left;
}

.ai-work-for-you-title div h2 {
    font-weight: 700;
    font-size: 70px;
}

.ai-work-for-you-title div p {
    font-weight: 500;
    font-size: 30px;
}

.ai-work-for-you-items {
    width: 100vw;
}

.ai-work-for-you-item {
    height: 20vh;
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(240, 217, 217);
    border-radius: 16px;
    font-weight: 500;
    font-size: 20px;
}

@media (max-width: 768px) {
    .ai-work-for-you-title div h2 {
        font-size: 40px;
    }
}