.stories-container {
    width: 100vw;
    padding: 60px 10%;
    background-color: #f4f6f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.project-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    min-height: 550px;
}

.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.project-title {
    font-size: 22px;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 10px;
}

.project-description {
    font-size: 16px;
    color: #4a4a4a;
    line-height: 1.6;
}

@media (max-width: 768px) {
    .section-heading {
        font-size: 28px;
    }

    .section-description {
        font-size: 16px;
    }

    .project-title {
        font-size: 20px;
    }

    .project-description {
        font-size: 14px;
    }
}