.testimonials-container {
    width: 100vw;
    padding: 60px 10%;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.testimonial-card {
    background-color: #f0f0f0;
    border-radius: 12px;
    padding: 20px;
    text-align: left;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    min-height: 445px;
    margin-left: 5%;
}

.testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.testimonial-description {
    font-size: 16px;
    color: #4a4a4a;
    line-height: 1.6;
    margin-bottom: 20px;
}

.testimonial-author {
    font-size: 14px;
    color: #4a4a4a;
}

.get-in-touch-container {
    margin-top: 40px;
}

.get-in-touch-button {
    padding: 30px;
    font-size: 30px;
}

@media (max-width: 768px) {
    .section-heading {
        font-size: 28px;
    }

    .section-description {
        font-size: 16px;
    }

    .testimonial-card {
        min-height: 200px;
    }

    .testimonial-description {
        font-size: 14px;
    }

    .get-in-touch-button {
        font-size: 16px;
    }
}